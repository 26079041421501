import React, { useCallback, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import {
  useCreateServiceDocumentSplits,
  useGetServiceDocumentSplits,
} from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useDebouncedCallback } from '@src/hooks/utils';
import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDocument } from '@src/types/documents';

import ChartOfAccountSplitsView from
  '@src/components/accounts_payable/invoice_queue/action_icons/chart_of_account_view/chart_of_account_splits_view';
import SplitCategoryForm from '@src/components/common_v2/category_splits_modal/category_splits_form';
import {
  categorySplitsValidation,
  ICategorySplitsData,
} from '@src/components/common_v2/category_splits_modal/schema';
import Form from '@src/components/ui_v2/form';
import {
  DOCUMENT_WIDTH_PERCENTAGE_HALF,
  MIN_DOCUMENT_WIDTH_PERCENTAGE,
} from '@src/components/ui_v2/layout/document_view/constant';
import { useDocumentViewWidth } from '@src/components/ui_v2/layout/document_view/hooks';

import {
  getCategorySplits,
} from '../../invoice_queue/action_icons/chart_of_account_view/chart_of_account_splits_helper';
import { getFormFieldLabel, isFieldRequired } from '../utils';

import styles from './styles.module.scss';

interface ICategoryFieldProps {
  document: IDocument;
  serviceDocument: IAccountsPayableServiceDocument;
  invoiceAmount: string;
}

const CategoryField = ({ document, serviceDocument, invoiceAmount }: ICategoryFieldProps) => {
  const { documentWidthPercentage, setDocumentWidthPercentage } = useDocumentViewWidth();
  const isCoaSplitsView = (100 - documentWidthPercentage) < DOCUMENT_WIDTH_PERCENTAGE_HALF;
  const splitsTableRef = useRef<HTMLDivElement>(null);

  const { mutate: saveSplits } = useCreateServiceDocumentSplits();
  const { data: serviceDocumentSplits } = useGetServiceDocumentSplits({ documentID: serviceDocument.documentId });

  const form = useForm<ICategorySplitsData>({
    mode:           'onBlur',
    reValidateMode: 'onChange',
    resolver:       yupResolver(categorySplitsValidation),
  });
  const { setValue, formState: { errors }, getValues } = form;

  const onSave = useDebouncedCallback(() => {
    const data = getValues();
    // only save splits if chart of account is set
    const splits = data.splits.filter((split) => !!split.chartOfAccountId);

    saveSplits({
      documentId:            serviceDocument.documentId,
      serviceDocumentSplits: splits,
    }, {
      onError: (error) => {
        toastr.error(error.message, 'Error');
      },
    });
  }, [getValues, saveSplits, serviceDocument], 1000);

  const handleEdit = useCallback(() => {
    setDocumentWidthPercentage(MIN_DOCUMENT_WIDTH_PERCENTAGE);
  }, [setDocumentWidthPercentage]);

  const handleAddSplitItem = useCallback(() => {
    setTimeout(() => {
      if (splitsTableRef.current) {
        splitsTableRef.current.scrollTo({
          top:      splitsTableRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    });
  }, []);

  useEffect(() => {
    const splits = getCategorySplits(
      document.businesses[0],
      serviceDocument,
      serviceDocumentSplits?.serviceDocumentSplits || [],
    );

    setValue('splits', splits);
  }, [document, serviceDocument, setValue, serviceDocumentSplits]);

  useEffect(() => {
    setValue('adjustmentAmount', invoiceAmount);
  }, [invoiceAmount, setValue]);

  return (
    isCoaSplitsView ? (
      <div className={ styles['document-fields'] }>
        <Form.StaticField
          label={
            getFormFieldLabel('Category', isFieldRequired(window.configData.account_payable_invoice_coa_field_id))
          }
        >
          <ChartOfAccountSplitsView
            shortSelectText
            accountsPayableServiceDocument={ serviceDocument }
            onEdit={ handleEdit }
          />
        </Form.StaticField>
      </div>
    ) : (
      <div className={ styles['splits-form-container'] }>
        <p className={ styles['splits-form-title'] }>Categorization</p>
        <SplitCategoryForm
          autoSave
          business={ document.businesses[0] }
          errorMessage={ errors.differenceAmount?.message }
          form={ form }
          tableClassName={ styles['splits-table'] }
          tableRef={ splitsTableRef }
          onAddSplitItem={ handleAddSplitItem }
          onAutoSave={ onSave }
        />
      </div>
    )
  );
};

export default React.memo(CategoryField);
