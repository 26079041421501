import { TAmount } from '@src/types/common';

const amountToNumber = (amount: TAmount | number | undefined | null, round: boolean = false) => {
  if (!amount) return 0;

  let num = typeof amount === 'number' ? amount : parseFloat(amount);
  num = round ? Math.round(num) : num;

  return num;
};

const negateAmount = (amount: TAmount | number | undefined | null) => {
  const negatedAmount = -1 * amountToNumber(amount);

  return negatedAmount;
};

const formatAmount = (amount: string) => {
  let formattedAmount = amount;
  // Remove the $ sign
  if (formattedAmount.startsWith('$')) {
    formattedAmount = formattedAmount.slice(1);
  }

  // remove , from the value
  formattedAmount = formattedAmount.replace(/,/g, '');

  return formattedAmount;
};

export {
  amountToNumber,
  negateAmount,
  formatAmount,
};
