import React, { useCallback, useMemo } from 'react';

import { useGetReceivableAccountsPayableServices } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { TID } from '@src/types/common';

import { ErrorNotification } from '@src/components/ui/notification';
import {
  ISelectInputProps,
  SelectInput, TOption,
} from '@src/components/ui_v2/inputs';

interface IReceivableAccountPayableServiceInputProps extends
  Omit<ISelectInputProps, 'value' | 'onChange' | 'options'>
{
  businessId: TID,
  isDisabled?: boolean,
  value?: TID | null,
  onChange?: (value: TID | null, option: TOption | null) => void,
}

const ReceivableAccountPayableServiceInput = ({
  businessId,
  isDisabled,
  value,
  onChange,
  ...props
}: IReceivableAccountPayableServiceInputProps): JSX.Element => {
  const query = useGetReceivableAccountsPayableServices({ businessId });

  const handleChange = useCallback((newValue: TOption | null) => {
    if (!onChange) return;

    onChange(newValue ? Number(newValue.value) : null, newValue);
  }, [onChange]);

  const options = useMemo(() => {
    if (query.data) {
      const retData = query.data?.map((account) => ({
        label: account.businessName,
        value: String(account.businessId),
      }));
      window.localStorage.setItem('receivable_account_payable_service_options', JSON.stringify(retData));

      return retData;
    }

    const tmpOptions = JSON.parse(window.localStorage.getItem('receivable_account_payable_service_options') || '[]');
    return tmpOptions.map((account: { label: string, value: string }) => ({
      label: account.label,
      value: String(account.value),
    }));
  }, [query.data]);

  const selectedOption = useMemo(() => {
    if (!value) return null;
    if (!query.data) return null;
    return options.find((acc: { label: string, value: string }) => acc.value === String(value)) || null;
  }, [value, options]);

  return (
    <>
      { query.isError && <ErrorNotification error={ query.error } title="Something went wrong." /> }
      <SelectInput
        isDisabled={ isDisabled }
        { ...props }
        options={ options }
        value={ selectedOption }
        onChange={ handleChange }
      />
    </>
  );
};

export default React.memo(ReceivableAccountPayableServiceInput);
