import React from 'react';

import classNames from 'classnames';

import ClearIndicator from './clear_indicator';

import styles from './styles.module.scss';

interface IClearInputWrapperProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode,
  hasValue: boolean,
  indicator?: React.ReactNode,
  prefixIcon?: React.ReactNode,
  suffixIcon?: React.ReactNode,
  hideClear?: boolean,
  size?: React.ComponentPropsWithoutRef<typeof ClearIndicator>['size'],
  onClear: () => void;
}

const ClearInputWrapper = ({
  children,
  className,
  hasValue,
  indicator,
  prefixIcon,
  suffixIcon,
  size,
  hideClear = false,
  onClear,
  ...props
}: IClearInputWrapperProps) => {
  const classes = classNames(
    styles['input-indicator-wrapper'],
    className,
    {
      [styles['input-indicator-wrapper-with-prefix']]: prefixIcon,
      [styles['input-indicator-wrapper-with-suffix']]: suffixIcon,
      [styles['input-indicator-wrapper-with-clear']]:  !hideClear,
    },
  );

  return (
    <div
      className={ classes }
      { ...props }
    >
      { prefixIcon && (
        <span className={ styles['input-indicator-wrapper-prefix'] }>
          { prefixIcon }
        </span>
      ) }
      { children }
      { suffixIcon && hideClear && (
        <span
          className={ styles['input-indicator-wrapper-suffix'] }
          role="button"
          tabIndex={ 0 }
        >
          { suffixIcon }
        </span>
      ) }
      {
        !hideClear && hasValue ? (
          <ClearIndicator absolute size={ size } onClick={ onClear } />
        ) : (
          indicator
        )
      }
    </div>
  );
};

export default ClearInputWrapper;
