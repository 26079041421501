import React, { forwardRef } from 'react';

import { UseQueryResult } from 'react-query';

import LocalQueryStatus from '@src/components/utils/local_query_status';

interface INoScrollTableProps extends React.ComponentPropsWithoutRef<'table'> {
  query?: UseQueryResult<any, Error> | UseQueryResult<any, Error>[],
  wrapperClassName?: string,
}

const NoScrollTable = forwardRef<HTMLDivElement, INoScrollTableProps>(({
  children,
  query,
  wrapperClassName,
  ...tableProps
}: INoScrollTableProps, ref) => {
  return (
    <div ref={ ref } className={ wrapperClassName }>
      <table { ...tableProps }>
        { children }
      </table>
      <LocalQueryStatus query={ query } />
    </div>
  );
});

NoScrollTable.displayName = 'NoScrollTable';

export default React.memo(NoScrollTable);
