import { useQuery, useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  IGetDocumentFieldsParams,
  IGetDocumentFieldsResponse,
  getDocumentFields,
  ISaveDocumentFieldValueParams,
  ISaveDocumentFieldValueResponse,
  saveDocumentFieldValue,
  updateVendor,
  IUpdateVendorParams,
  IUpdateVendorResponse,
} from '@src/requests/document_field';

const DOCUMENT_FIELDS_QUERY = 'document_fields';

const useGetDocumentFields = (params: IGetDocumentFieldsParams) => {
  return useQuery<IGetDocumentFieldsResponse, Error>(
    [DOCUMENT_FIELDS_QUERY, params],
    () => getDocumentFields(params),
  );
};

const useSaveDocumentFieldValue = () => {
  const queryClient = useQueryClient();

  return useMutation<ISaveDocumentFieldValueResponse, Error, ISaveDocumentFieldValueParams>(
    saveDocumentFieldValue,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.accountsPayableServiceDocuments);
      },
    },
  );
};

const useUpdateVendor = () => {
  return useMutation<IUpdateVendorResponse, Error, IUpdateVendorParams>(
    updateVendor,
  );
};

export {
  useGetDocumentFields,
  useSaveDocumentFieldValue,
  useUpdateVendor,
};
